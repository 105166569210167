import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import GlobalStyle from './global/styles/global';
import MainRoutes from './Routes';

import 'react-toastify/dist/ReactToastify.css';

const App: React.FC = () => {
  return (
    <Router>
      <GlobalStyle />
      <MainRoutes />
      <ToastContainer />
    </Router>
  );
};

export default App;

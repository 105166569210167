import { all, AllEffect, ForkEffect } from 'redux-saga/effects';

import signInSagas from './slices/SignIn/SignIn.sagas';

export default function* rootSaga(): Generator<
  AllEffect<AllEffect<ForkEffect<never>>>,
  unknown,
  unknown
> {
  return yield all([signInSagas]);
}

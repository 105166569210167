/* eslint-disable default-case */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import makeAnimated from 'react-select/animated';
import MultiSelect, { StylesConfig } from 'react-select';
import { sendImageSpaces } from '../../../services/Adverts.service';
import {
  createPartner,
  updatePartner,
  deletePartner,
  getPartner,
} from '../../../services/Partners.service';
import { useAppSelector } from '../../../store';
import PartnerType from '../../../types/partners.type';
import { theme } from '../../../global/styles/theme';
import FloatFooter from '../../../Components/FloatFooter';
import FileUpload from '../../../Components/FileUpload';
import '../../../custom.scss';

import {
  Container,
  Dot,
  GoBackIcon,
  NavGoBack,
  NavGoBackContainer,
  NavGoBackName,
  Context,
  InputStyled,
  InputMaskStyled,
  RowForm,
  Label,
  ButtonContainer,
  ButtonStyled,
  Header,
  LabelId,
  LoginSpinner,
  Textarea,
  ButtonStatus,
  IconPlay,
  ContainerButtonStatus,
} from './styles';

interface PartnerCategoriesProps {
  value: string;
  label: string;
  isFixed?: boolean;
}
const PartnersDetails = () => {
  const navigate = useNavigate();
  const { state }: { state: any } = useLocation();
  const { partnerId, partnerCategories, partnerCategoriesId } = state || {
    partnerId: '',
    partnerCategories: [],
    partnerCategoriesId: [],
  };
  const [title, setTitle] = useState('');
  const [slogan, setSlogan] = useState('');
  const [description, setDescription] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [buttunUrl, setButtunUrl] = useState('');
  const [messageText, setMessageText] = useState('');
  const [partnerCategoryId, setPartnerCategoryId] =
    useState<string[]>(partnerCategoriesId);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [image, setImage] = useState<FormData>();
  const [titleButton, setTitleButton] = useState('Adicionar');
  const [statusPartner, setStatusPartner] = useState('inactive');

  const company = useAppSelector(
    stateSelector => stateSelector.companyReducer.companySelected,
  );

  const colourStyles: StylesConfig = {
    container: (styles: any) => ({
      ...styles,
      borderRadius: 8,
      borderWidth: 0.5,
      borderStyle: 'solid',
      borderColor: theme.colors.borderInput,
      marginTop: '0.6rem',
    }),
    control: (styles: any) => ({
      ...styles,
      borderRadius: 8,
      borderWidth: 0.5,
      borderStyle: 'none',
      ':hover': {
        ...styles[':hover'],
        borderColor: theme.colors.primary,
        boxShadow: `0 0 0 1px ${theme.colors.primary}`,
      },
    }),
  };

  const animatedComponents = makeAnimated();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const sendImage = async (partnerAddId: string) => {
    if (!image) return '';

    const { status, msg, data } = await sendImageSpaces(
      company.id_group,
      partnerAddId,
      image,
    );

    if (!status) {
      toast.error(msg);
      return '';
    }

    return data.img.url;
  };

  const handleChangeImage = (img: FormData) => {
    setImage(img);
  };

  const handleGoBack = () => {
    navigate('/customers/partners/partners-list');
  };

  const getPartnerSelected = useCallback(async () => {
    const { data, status, msg } = await getPartner(partnerId);

    if (!status) toast.error(msg);

    setTitle(data.title);
    setDescription(data.description);
    setSlogan(data.slogan);
    setStatusPartner(data.status);
    setPhoneNumber(data.phone_number);
    setImageUrl(data.banner_url);
    setMessageText(data.message_text);
    setButtunUrl(data.action_button_url);
  }, [partnerId]);

  useEffect(() => {
    if (partnerId) {
      setTitleButton('Atualizar');
      getPartnerSelected();
    }
  }, [getPartnerSelected, partnerId]);

  const schema = Yup.object().shape({
    title: Yup.string().required('O nome é obrigatório'),
    description: Yup.string().required('A descrição é obrigatória'),
    slogan: Yup.string().required('O slogan é obrigatório'),
    phone_number: Yup.string(),
    banner_url: Yup.string(),
    message_text: Yup.string(),
    partner_categories: Yup.array().of(
      Yup.string().required('A categoria é obrigatório'),
    ),
    action_button_url: Yup.string().url('Deve ser uma URL válida'),
  });

  const handleAddPartner = async () => {
    const values: PartnerType = {
      title,
      description,
      slogan,
      banner_url: 'url',
      phone_number: phoneNumber,
      status: statusPartner,
      created_by: company._id,
      id_group: company.id_group,
      partner_categories: partnerCategoryId,
      action_button_url: buttunUrl,
      message_text: messageText,
    };

    if (!image) {
      setLoading(false);
      return toast.error('A imagem é obrigatória');
    }

    const isValid = await schema
      .validate(values)
      .catch(({ errors }) => errors[0]);

    if (!Object.is(isValid, values)) {
      setLoading(false);
      return toast.error(isValid);
    }

    const {
      status: statusCreate,
      msg: msgCreate,
      data,
    } = await createPartner(values);

    if (!statusCreate) {
      setLoading(false);
      return toast.error(msgCreate);
    }

    values.banner_url = await sendImage(data._id);

    const { status } = await updatePartner(data._id, values);

    setLoading(false);

    if (!status)
      toast.error(
        'Não foi possível adicionar a imagem ao parceiro. Atualize o parceiro adicionando a imagem.',
      );

    toast.success('Parceiro adicionado com sucesso!');
    return handleGoBack();
  };

  const handleUpdatePartner = async () => {
    if (!partnerCategoryId.length) {
      setLoading(false);
      return toast.error('A categoria é obrigatório');
    }

    const values: PartnerType = {
      title,
      description,
      slogan,
      phone_number: phoneNumber,
      banner_url: image ? await sendImage(partnerId) : imageUrl,
      status: statusPartner,
      id_group: company.id_group,
      partner_categories: partnerCategoryId,
      action_button_url: buttunUrl,
      message_text: messageText,
    };

    const isValid = await schema
      .validate(values)
      .catch(({ errors }) => errors[0]);

    if (!Object.is(isValid, values)) {
      setLoading(false);
      return toast.error(isValid);
    }

    const { status, msg } = await updatePartner(partnerId, values);

    setLoading(false);
    if (!status) return toast.error(msg);

    toast.success('Parceiro atualizado com sucesso!');
    return handleGoBack();
  };

  const handleDeletePartner = async () => {
    const { status, msg } = await deletePartner(partnerId);

    if (!status) return toast.error(msg);

    toast.success('Parceiro deletado com sucesso!');
    return handleGoBack();
  };

  const handleSubmit = () => {
    setLoading(true);
    if (partnerId) return handleUpdatePartner();
    return handleAddPartner();
  };

  const handleStatusPartners = () => {
    if (statusPartner === 'active') setStatusPartner('inactive');
    else setStatusPartner('active');
  };

  return (
    <>
      <FloatFooter>
        <ButtonContainer>
          {partnerId && (
            <ButtonStyled variant="secondary" onClick={handleShow}>
              Deletar
            </ButtonStyled>
          )}
          <ButtonStyled
            variant="primary"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? (
              <LoginSpinner animation="border" variant="light" />
            ) : (
              titleButton
            )}
          </ButtonStyled>
        </ButtonContainer>
      </FloatFooter>
      <Container>
        <Header>
          <NavGoBackContainer onClick={handleGoBack}>
            <GoBackIcon />
            <NavGoBack>Lista</NavGoBack>
            <Dot />
            <NavGoBackName>{title || 'Novo parceiro'}</NavGoBackName>
          </NavGoBackContainer>
          {partnerId && <LabelId>{`ID: ${partnerId}`}</LabelId>}
        </Header>
        <Context>
          <ContainerButtonStatus>
            <ButtonStatus variant="secondary" onClick={handleStatusPartners}>
              <IconPlay />
              {statusPartner === 'active' ? 'Desativar' : 'Ativar'}
            </ButtonStatus>
          </ContainerButtonStatus>
          <RowForm>
            <Label>Categorias</Label>
            <MultiSelect
              theme={(themeSelect: any) => ({
                ...themeSelect,
                colors: {
                  ...themeSelect.colors,
                  primary: theme.colors.primary,
                },
              })}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Selecione as categorias"
              styles={colourStyles}
              closeMenuOnSelect={false}
              components={animatedComponents}
              isMulti
              options={partnerCategories}
              defaultValue={
                partnerId &&
                partnerCategoriesId?.map((partnerC: string) => {
                  return partnerCategories?.filter(
                    (companyList: PartnerCategoriesProps) =>
                      companyList.value === partnerC,
                  )[0];
                })
              }
              onChange={(e: any) =>
                setPartnerCategoryId(
                  e.map(
                    (partnerCategoryTmp: PartnerCategoriesProps) =>
                      partnerCategoryTmp.value,
                  ),
                )
              }
            />
          </RowForm>
          <RowForm>
            <Label>Nome</Label>
            <InputStyled
              name="name"
              type="text"
              value={title}
              onChange={(e: any) => setTitle(e.target.value)}
            />
          </RowForm>
          <RowForm>
            <Label>Slogan</Label>
            <InputStyled
              name="sloga"
              type="text"
              value={slogan}
              onChange={(e: any) => setSlogan(e.target.value)}
            />
          </RowForm>
          <RowForm>
            <Label>Número de Telefone</Label>
            <InputMaskStyled
              name="phone_number"
              type="text"
              mask="(99) 9 9999-9999"
              value={phoneNumber}
              onChange={(e: any) => setPhoneNumber(e.target.value)}
            />
          </RowForm>
          {phoneNumber && (
            <RowForm>
              <Label>Mensagem</Label>
              <InputStyled
                name="messageText"
                type="text"
                value={messageText}
                onChange={(e: any) => setMessageText(e.target.value)}
              />
            </RowForm>
          )}
          <RowForm>
            <Label>Descrição</Label>
            <Textarea
              name="descricao"
              as="textarea"
              placeholder="Escreva uma descrição..."
              rows={6}
              value={description}
              onChange={(e: any) => setDescription(e.target.value)}
            />
          </RowForm>
          <RowForm>
            <Label>URL botão de ação</Label>
            <InputStyled
              name="buttunUrl"
              type="text"
              value={buttunUrl}
              onChange={(e: any) => setButtunUrl(e.target.value)}
            />
          </RowForm>
          <RowForm>
            <Label>Mídia</Label>
            <FileUpload onChangeFile={handleChangeImage} urlImage={imageUrl} />
          </RowForm>
        </Context>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Atenção!</Modal.Title>
          </Modal.Header>
          <Modal.Body>Realmente deseja deletar o parceiro {title}?</Modal.Body>
          <Modal.Footer>
            <ButtonStyled variant="secondary" onClick={handleClose}>
              Cancelar
            </ButtonStyled>
            <ButtonStyled variant="danger" onClick={handleDeletePartner}>
              Deletar
            </ButtonStyled>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
};

export default PartnersDetails;

import FormattedResponseType from '../types/formattedResponse.type';
// import CustomersType from '../types/customers.type';
import AdvancedFilterType from '../types/customerAdvancedFilter.type';
import Api, { ORIGIN_API } from './api';

export const getCustomers = async (
  groupId: string,
  limit: number,
  page: number,
  data?: AdvancedFilterType,
): Promise<FormattedResponseType> => {
  let response;
  const formattedResponse: FormattedResponseType = {
    status: false,
    msg: '',
    data: null,
  };

  try {
    if (data && Object.keys(data).length) {
      response = await Api.post(
        `customer/${ORIGIN_API}/${groupId}/${limit}/${page}/list`,
        data,
      );
    } else {
      response = await Api.post(
        `customer/${ORIGIN_API}/${groupId}/${limit}/${page}/list`,
      );
    }

    formattedResponse.status = true;
    formattedResponse.data = response.data;
  } catch (error) {
    formattedResponse.status = false;

    if (error.response) {
      if (error.response.status < 500) {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      } else {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      }
    } else if (error.request || error.message) {
      formattedResponse.msg = 'Não foi possível conectar a plataforma';
    }
  }

  return formattedResponse;
};

export const isAModifiedCustomer = async (
  customerId: string,
): Promise<FormattedResponseType> => {
  let response;
  const formattedResponse: FormattedResponseType = {
    status: false,
    msg: '',
    data: null,
  };

  try {
    response = await Api.get(
      `customer/${ORIGIN_API}/edit/${customerId}/pending/show`,
    );

    formattedResponse.status = true;
    // eslint-disable-next-line prefer-destructuring
    formattedResponse.data = response.data[0];
  } catch (error) {
    formattedResponse.status = false;

    if (error.response) {
      if (error.response.status < 500) {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      } else {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      }
    } else if (error.request || error.message) {
      formattedResponse.msg = 'Não foi possível conectar a plataforma';
    }
  }

  return formattedResponse;
};

export const validationCustomerModified = async (
  status: string,
  customerId: string,
  profileId: string,
): Promise<FormattedResponseType> => {
  let response;
  const formattedResponse: FormattedResponseType = {
    status: false,
    msg: '',
    data: null,
  };

  try {
    response = await Api.put(
      `customer/${ORIGIN_API}/${status}/${customerId}/update?profileId=${profileId}`,
    );

    formattedResponse.status = true;
    formattedResponse.data = response.data;
  } catch (error) {
    formattedResponse.status = false;

    if (error.response) {
      if (error.response.status < 500) {
        switch (error.response.data.code) {
          case 'pending_editions_not_found':
            formattedResponse.msg = 'Nenhuma edição pendente para este cliente';
            break;
          case 'failed':
            formattedResponse.msg = 'Não foi possível atualizar o cliente';
            break;

          default:
            formattedResponse.msg = 'Não foi possível conectar a plataforma';
            break;
        }
      } else {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      }
    } else if (error.request || error.message) {
      formattedResponse.msg = 'Não foi possível conectar a plataforma';
    }
  }

  return formattedResponse;
};

export const getActivitiesCustomer = async (
  customerId: string,
  limit: number,
  page: number,
): Promise<FormattedResponseType> => {
  let response;
  const formattedResponse: FormattedResponseType = {
    status: false,
    msg: '',
    data: null,
  };

  try {
    response = await Api.get(
      `customer/${ORIGIN_API}/${customerId}/${limit}/${page}/accessreport`,
    );

    formattedResponse.status = true;
    formattedResponse.data = response.data;
  } catch (error) {
    formattedResponse.status = false;

    if (error.response) {
      if (error.response.status < 500) {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      } else {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      }
    } else if (error.request || error.message) {
      formattedResponse.msg = 'Não foi possível conectar a plataforma';
    }
  }

  return formattedResponse;
};

export const getChargesCustomer = async (
  groupId: string,
  cpf: string,
): Promise<FormattedResponseType> => {
  let response;
  const formattedResponse: FormattedResponseType = {
    status: false,
    msg: '',
    data: null,
  };

  try {
    response = await Api.get(`charges/${groupId}/${cpf}/list`);

    formattedResponse.status = true;
    formattedResponse.data = response.data;
  } catch (error) {
    formattedResponse.status = false;

    if (error.response) {
      if (error.response.status < 500) {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      } else {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      }
    } else if (error.request || error.message) {
      formattedResponse.msg = 'Não foi possível conectar a plataforma';
    }
  }

  return formattedResponse;
};

export const getDashboardCustomer = async (
  groupId: string,
): Promise<FormattedResponseType> => {
  let response;
  const formattedResponse: FormattedResponseType = {
    status: false,
    msg: '',
    data: null,
  };

  try {
    response = await Api.get(`dashboard/${groupId}/customers`);

    formattedResponse.status = true;
    formattedResponse.data = response.data;
  } catch (error) {
    formattedResponse.status = false;

    if (error.response) {
      if (error.response.status < 500) {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      } else {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      }
    } else if (error.request || error.message) {
      formattedResponse.msg = 'Não foi possível conectar a plataforma';
    }
  }

  return formattedResponse;
};

import React from 'react';
import { Container, Title } from './styles';

interface TitleLabelProps {
  title: string;
}

const TitleLabel = ({ title }: TitleLabelProps) => {
  return (
    <Container>
      <Title>{title}</Title>
    </Container>
  );
};

export default TitleLabel;

import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import {
  Context,
  Container,
  Title,
  Menu,
  IconPeople,
  IconHandShake,
  ContainerMenu,
  ContainerButton,
  ButtonClose,
} from './styles';

interface SubMenuProps {
  handleNavegate: (
    path: string,
    showMenu: boolean,
    pathDefault: boolean,
  ) => void;
  isShowSubMenu: boolean;
  setShowSubMenu: (isShow: boolean) => void;
}

const SubMenu = ({
  handleNavegate,
  isShowSubMenu,
  setShowSubMenu,
}: SubMenuProps) => {
  const location = useLocation();

  const CustomersMenu = useCallback(() => {
    return (
      <>
        <Title>
          <IconPeople />
          Clientes
        </Title>
        <ContainerMenu
          selected={location.pathname === '/customers'}
          onClick={() => handleNavegate('/customers', false, true)}
        >
          <Menu>Visão Geral</Menu>
        </ContainerMenu>
        <ContainerMenu
          selected={location.pathname === '/customers/customers-list'}
          onClick={() =>
            handleNavegate('/customers/customers-list', false, true)
          }
        >
          <Menu>Lista de pessoas</Menu>
        </ContainerMenu>
        <ContainerMenu
          selected={location.pathname === '/customers/contracts'}
          onClick={() => handleNavegate('/customers/contracts', false, true)}
        >
          <Menu>Contratos</Menu>
        </ContainerMenu>
        <ContainerMenu
          selected={location.pathname === '/customers/notifications'}
          onClick={() =>
            handleNavegate('/customers/notifications', false, true)
          }
        >
          <Menu>Notificações</Menu>
        </ContainerMenu>
        <ContainerMenu
          selected={location.pathname === '/customers/adverts'}
          onClick={() => handleNavegate('/customers/adverts', false, true)}
        >
          <Menu>Campanhas</Menu>
        </ContainerMenu>
        <Title
          style={{
            width: '100%',
            borderBottom: '1px',
            borderColor: '#f5f5f5',
            borderWidth: '2px',
            borderBottomStyle: 'solid',
            margin: '25px 0px',
          }}
        />
        <Title>
          <IconHandShake />
          Parceiros
        </Title>
        <ContainerMenu
          selected={location.pathname === '/customers/partners'}
          onClick={() => handleNavegate('/customers/partners', false, true)}
        >
          <Menu>Visão Geral</Menu>
        </ContainerMenu>
        <ContainerMenu
          selected={location.pathname === '/customers/partners/partners-list'}
          onClick={() =>
            handleNavegate('/customers/partners/partners-list', false, true)
          }
        >
          <Menu>Lista de parceiros</Menu>
        </ContainerMenu>
      </>
    );
  }, [handleNavegate, location.pathname]);

  return (
    <Container>
      <Context isShowSubMenu={isShowSubMenu}>
        <ContainerButton>
          <ButtonClose onClick={() => setShowSubMenu(false)}>x</ButtonClose>
        </ContainerButton>
        {location.pathname.indexOf('customers') !== -1 && <CustomersMenu />}
      </Context>
    </Container>
  );
};

export default SubMenu;

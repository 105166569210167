import React from 'react';

import { FooterStyled } from './styles';

type FloatFooterProps = {
  children: React.ReactNode;
};

const FloatFooter = ({ children }: FloatFooterProps) => {
  return <FooterStyled>{children}</FooterStyled>;
};

export default FloatFooter;

import styled from 'styled-components';
import { Form } from 'react-bootstrap';

import { theme } from '../../global/styles/theme';

export const Container = styled.div`
  display: flex;

  @media (max-width: 550px) {
    width: 100%;
  }
`;

export const ContainerIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.primary};
  min-width: 3.5rem;
  max-width: 4rem;
  width: 100%;
  min-height: 2.5rem;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  cursor: pointer;
  opacity: 1;
  transition: 0.3s;

  &:active {
    opacity: 0.6;
  }
`;

export const Input = styled(Form.Control)`
  align-items: center;
  display: inline-block;
  vertical-align: middle;
  border-radius: 0;
  min-width: 10rem;
  max-width: 32rem;
  width: 100%;
  min-height: 2.5rem;
  background-color: ${theme.colors.white};
  -ms-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  color: ${theme.colors.white};
  font-weight: 400;
  font-size: 13px;
  border: none;
  border-width: 1px;
  border-style: solid;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  ::placeholder {
    color: #cbcbcb;
  }
  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &::placeholder,
  &:-ms-input-placeholder {
    color: ${theme.colors.background};
    font-size: 14px;
  }

  &:focus {
    outline: none;
    border: 1px solid ${theme.colors.primary};
    box-shadow: none;
    background-color: #ffffff;
  }
`;

import axios from 'axios';

export const ORIGIN_API = 'panel';

const Api = axios.create({
  baseURL: 'https://api.plataformavirtus.com.br/v1/',
  timeout: 10000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export default Api;

import FormattedResponseType from '../types/formattedResponse.type';
import SettingsSurveyType from '../types/settingsSurvey.type';
import Api from './api';

export const getSurvey = async (
  groupId: string,
  id_notification_settings: string,
  page?: number,
  limit?: number,
  filterData?: SettingsSurveyType,
): Promise<FormattedResponseType> => {
  let response;
  const formattedResponse: FormattedResponseType = {
    status: false,
    msg: '',
    data: null,
  };

  try {
    if (filterData) {
      response = await Api.post(
        `notification/survey/report/${groupId}/${id_notification_settings}/${page}/${limit}/list`,
        filterData,
      );
    } else {
      response = await Api.post(
        `notification/survey/report/${groupId}/${id_notification_settings}/${page}/${limit}/list`,
      );
    }

    formattedResponse.status = true;
    formattedResponse.data = response.data;
  } catch (error) {
    formattedResponse.status = false;

    if (error.response) {
      if (error.response.status < 500) {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      } else {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      }
    } else if (error.request || error.message) {
      formattedResponse.msg = 'Não foi possível conectar a plataforma';
    }
  }

  return formattedResponse;
};

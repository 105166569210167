import React from 'react';

import { QuestionStyled, IconQuestion } from './styles';

const QuestionTooltip = () => {
  return (
    <QuestionStyled>
      <IconQuestion />
    </QuestionStyled>
  );
};

export default QuestionTooltip;

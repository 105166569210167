import styled from 'styled-components';

export const Title = styled.h1`
  color: rgb(141 90 188);
  font-size: 1.2rem;
  margin-bottom: 2rem;
`;

export const Container = styled.div`
  width: fit-content;
`;

import styled from 'styled-components';
import { Navbar } from 'react-bootstrap';

import { theme } from '../../global/styles/theme';

export const NavbarStyled = styled(Navbar)`
  padding-top: 4px;
  padding-bottom: 4px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  .container {
    max-width: 100%;
  }
`;
export const FooterStyled = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: calc(100% - 99px);
  background: ${theme.colors.white};
  border-top: 2px solid ${theme.colors.background};
  height: 55px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 99999;
  animation: showfooterstyled 0.7s;
  @keyframes showfooterstyled {
    from {
      opacity: 0;
      bottom: -5rem;
      animation: 0.5s;
    }
    to {
      opacity: 1;
      bottom: 0;
    }
  }
`;

export const NavStyled = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
`;

export const Tag = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  color: red;
`;

export const Divider = styled.div`
  border-left: 1px solid ${theme.colors.background};
  height: 40px;
`;

export const DotOn = styled.div`
  border-radius: 50%;
  background-color: ${theme.colors.green};
  height: 8px;
  width: 8px;
`;

export const ContextNameUser = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Pagination } from 'react-bootstrap';
import TitlePage from '../../Components/TitlePage';
import Flag from '../../Components/Flag';
import { getNotifications } from '../../services/Notification.service';
import { useAppSelector } from '../../store';
import NotificationsType from '../../types/notifications.type';
import SkeletonListEndBar from '../../Components/SkeletonListEndBar';
import getTranslation from '../../utils/getTranslation';
// import { selectTermsContract } from '../../store/slices/TermsContract/TermsContract.slice';

import {
  Container,
  Context,
  AlertTable,
  Header,
  ButtonFilter,
  THead,
  TableStyled,
  Td,
  Th,
  Tr,
  PaginationStyled,
  AreaPagination,
  DescriptionPagination,
} from './styles';

const Notifications: React.FC = () => {
  const NOTIFICATIONS_PER_PAGE = 10;
  const HOME_PAGE = 1;

  const navigate = useNavigate();
  const company = useAppSelector(state => state.companyReducer.companySelected);

  const [notifications, setNotifications] = useState<NotificationsType[]>([]);
  const [totalNotifications, setTotalNotifications] = useState<number>(0);
  const [paginationItem, setPaginationItem] = useState<number[]>([]);
  const [selectedPage, setSelectedPage] = useState<number>(HOME_PAGE);
  const [loading, setLoading] = useState<boolean>(false);
  const [descriptionPagination, setDescriptionPagination] = useState('');
  const [pageInit, setPageInit] = useState(0);
  const [pageLimit, setPageLimit] = useState(5);

  const notificationInitial: NotificationsType = {
    _id: '',
    name: '',
    message: '',
    status: true,
    type: '',
  };

  const getAllNotifications = useCallback(
    async (page: number, limit: number) => {
      const { data, status, msg } = await getNotifications(
        company.id_group,
        page,
        limit,
      );

      if (!status) {
        setLoading(false);
        toast.error(msg);
      } else {
        setNotifications(data?.notificationSettings);
        setTotalNotifications(data?.totalDocs);

        const countItem = [];
        for (
          let num = 1;
          num <= Math.ceil(data.totalDocs / NOTIFICATIONS_PER_PAGE);
          num += 1
        ) {
          countItem.push(num);
        }

        if (countItem.length <= 0 && data?.notificationSettings.length > 0) {
          setPaginationItem([1]);
        } else setPaginationItem(countItem);
        setLoading(false);
      }
    },
    [company],
  );

  const handleViewContractDetail = (terms: NotificationsType) => {
    navigate('/customers/notifications-details', {
      state: { notificationId: terms._id, companiesIds: terms.companies },
    });
  };

  const changePage = (page: number) => {
    if (page > 0 && page <= paginationItem.length) {
      if (page > 5) {
        setPageInit(page - 5);
        setPageLimit(page);
      } else {
        setPageInit(0);
        setPageLimit(5);
      }

      setSelectedPage(page);
      getAllNotifications(page, NOTIFICATIONS_PER_PAGE);
    }
  };

  useEffect(() => {
    setLoading(true);
    getAllNotifications(HOME_PAGE, NOTIFICATIONS_PER_PAGE);
  }, [getAllNotifications]);

  useEffect(() => {
    setDescriptionPagination(
      `${
        (selectedPage - 1) * NOTIFICATIONS_PER_PAGE +
        notifications.length -
        (notifications.length - 1)
      }-${
        (selectedPage - 1) * NOTIFICATIONS_PER_PAGE + notifications.length
      } de ${totalNotifications} Notificações`,
    );
  }, [notifications.length, selectedPage, totalNotifications]);

  return (
    <Container>
      <Header>
        <TitlePage title="Lista de Notificações" />
        <ButtonFilter
          variant="primary"
          onClick={() => handleViewContractDetail(notificationInitial)}
        >
          Adicionar Notificação
        </ButtonFilter>
      </Header>
      <Context>
        {loading && <SkeletonListEndBar />}
        {!loading && (
          <TableStyled hover>
            <THead>
              <tr>
                <Th>Nome</Th>
                <Th>Tipo</Th>
                <Th>Status</Th>
              </tr>
            </THead>
            <tbody>
              {notifications.map((notification: NotificationsType) => (
                <Tr
                  key={notification._id}
                  onClick={() => handleViewContractDetail(notification)}
                >
                  <Td>{notification.name}</Td>
                  <Td>{getTranslation(notification.type)}</Td>
                  <Td>
                    <Flag
                      text={notification.status ? 'Ativo' : 'Inativo'}
                      status={notification.status ? 'success' : 'warning'}
                    />
                  </Td>
                </Tr>
              ))}
            </tbody>
            {notifications.length > 0 && (
              <tfoot>
                <tr>
                  <td colSpan={100}>
                    <AreaPagination>
                      <DescriptionPagination style={{ visibility: 'hidden' }}>
                        {descriptionPagination}
                      </DescriptionPagination>
                      <PaginationStyled size="sm">
                        <Pagination.First
                          onClick={() => changePage(HOME_PAGE)}
                        />
                        <Pagination.Prev
                          onClick={() => changePage(selectedPage - 1)}
                        />
                        {paginationItem
                          .slice(pageInit, pageLimit)
                          .map((page: number) => (
                            <Pagination.Item
                              key={page}
                              active={page === selectedPage}
                              onClick={() => changePage(page)}
                            >
                              {page}
                            </Pagination.Item>
                          ))}
                        <Pagination.Next
                          onClick={() => changePage(selectedPage + 1)}
                        />
                        <Pagination.Last
                          onClick={() => changePage(paginationItem.length)}
                        />
                      </PaginationStyled>
                      <DescriptionPagination>
                        {descriptionPagination}
                      </DescriptionPagination>
                    </AreaPagination>
                  </td>
                </tr>
              </tfoot>
            )}
          </TableStyled>
        )}
        {!loading && notifications.length <= 0 && (
          <AlertTable>Nenhuma notificação encontrada</AlertTable>
        )}
      </Context>
    </Container>
  );
};

export default Notifications;

import React from 'react';

import { AdsExampleStyled, IconAdsExample } from './styles';

const AdsExample = () => {
  return (
    <AdsExampleStyled>
      <IconAdsExample />
    </AdsExampleStyled>
  );
};

export default AdsExample;

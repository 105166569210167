import styled from 'styled-components';
import { Form } from 'react-bootstrap';

import { theme } from '../../global/styles/theme';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Input = styled(Form.Control)`
  display: inline-block;
  vertical-align: middle;
  border-radius: 0;
  min-width: 50px;
  max-width: 635px;
  width: 100%;
  min-height: 36px;
  background-color: ${theme.colors.background};
  border: none;
  -ms-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  color: #3b3f5c;
  font-weight: 400;
  font-size: 14px;
  border-bottom: 1px solid #e0e6ed;
  padding: 0px 0px 10px 10px;

  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder {
    color: #bfc9d4;
    font-size: 14px;
  }

  &:focus {
    outline: none;
    border-bottom: 1px solid #8d5abc;
    box-shadow: none;
  }
`;

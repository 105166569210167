import React from 'react';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import {
  NavbarStyled,
  Divider,
  DotOn,
  ContextNameUser,
  NavStyled,
} from './styles';
import { useAppSelector, useAppDispatch } from '../../store';
import { signOut } from '../../store/slices/SignIn/SignIn.slice';
import { removeCompany } from '../../store/slices/Company/Company.slice';

const Header = () => {
  const user = useAppSelector(state => state.signInReducer?.user);
  const company = useAppSelector(state => state.companyReducer.companySelected);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleLogout = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    dispatch(signOut());
    dispatch(removeCompany());
  };

  return (
    <NavbarStyled bg="light" expand>
      <Container>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <DotOn />
          <Nav>
            {!!company.name && (
              <>
                <Nav.Link href="#" onClick={() => navigate('/')}>
                  <NavStyled>{company.name}</NavStyled>
                </Nav.Link>
                <Divider />
              </>
            )}
            <ContextNameUser style={{ marginLeft: 10, paddingLeft: 0 }}>
              <NavDropdown title={user?.name}>
                <NavDropdown.Item href="#" onClick={handleLogout}>
                  Sair
                </NavDropdown.Item>
              </NavDropdown>
            </ContextNameUser>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </NavbarStyled>
  );
};

export default Header;

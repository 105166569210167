/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import CustomerType from '../../../types/customer.type';

export interface CustomerState {
  customer: CustomerType;
}

const initialState: CustomerState = {
  customer: {
    id_user: '',
    id_customer: '',
    id_group: '',
    name: '',
    username: '',
    last_login: '',
    origin: '',
    type: '',
    birth: '',
    created_at: '',
    state: '',
    city: '',
    code: '',
    gender: '',
    neighborhood: '',
    overdue_installments: 0,
  },
};

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    selectCustomer: (state, action: PayloadAction<CustomerType>) => {
      state.customer = action.payload;
    },
  },
});

export const { selectCustomer } = customerSlice.actions;

export default customerSlice.reducer;

import FormattedResponseType from '../types/formattedResponse.type';
import TermsContractsType from '../types/termsContracts.type';
import Api from './api';

export const getContracts = async (
  groupId: string,
  page?: number,
  limit?: number,
): Promise<FormattedResponseType> => {
  let response;
  const formattedResponse: FormattedResponseType = {
    status: false,
    msg: '',
    data: null,
  };

  try {
    if (limit && page) {
      response = await Api.get(`contractterm/${groupId}/${page}/${limit}/list`);
    } else {
      response = await Api.get(`contractterm/${groupId}/list`);
    }

    formattedResponse.status = true;
    formattedResponse.data = response.data;
  } catch (error) {
    formattedResponse.status = false;

    if (error.response) {
      if (error.response.status < 500) {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      } else {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      }
    } else if (error.request || error.message) {
      formattedResponse.msg = 'Não foi possível conectar a plataforma';
    }
  }

  return formattedResponse;
};

export const updateContract = async (
  TermsContractId: string,
  data: TermsContractsType,
): Promise<FormattedResponseType> => {
  let response;
  const formattedResponse: FormattedResponseType = {
    status: false,
    msg: '',
    data: null,
  };

  try {
    response = await Api.put(`contractterm/${TermsContractId}/update`, data);

    formattedResponse.status = true;
    formattedResponse.data = response.data;
  } catch (error) {
    formattedResponse.status = false;

    if (error.response) {
      if (error.response.status < 500) {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      } else {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      }
    } else if (error.request || error.message) {
      formattedResponse.msg = 'Não foi possível conectar a plataforma';
    }
  }

  return formattedResponse;
};

export const createContract = async (
  data: TermsContractsType,
): Promise<FormattedResponseType> => {
  let response;
  const formattedResponse: FormattedResponseType = {
    status: false,
    msg: '',
    data: null,
  };

  try {
    response = await Api.post(`contractterm/create`, data);

    formattedResponse.status = true;
    formattedResponse.data = response.data;
  } catch (error) {
    formattedResponse.status = false;

    if (error.response) {
      if (error.response.status < 500) {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      } else {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      }
    } else if (error.request || error.message) {
      formattedResponse.msg = 'Não foi possível conectar a plataforma';
    }
  }

  return formattedResponse;
};

export const deleteContract = async (
  TermsContractId: string,
): Promise<FormattedResponseType> => {
  let response;
  const formattedResponse: FormattedResponseType = {
    status: false,
    msg: '',
    data: null,
  };

  try {
    response = await Api.delete(`contractterm/${TermsContractId}/delete`);

    formattedResponse.status = true;
    formattedResponse.data = response.data;
  } catch (error) {
    formattedResponse.status = false;

    if (error.response) {
      if (error.response.status < 500) {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      } else {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      }
    } else if (error.request || error.message) {
      formattedResponse.msg = 'Não foi possível conectar a plataforma';
    }
  }

  return formattedResponse;
};

export const getContract = async (
  TermsContractId: string,
): Promise<FormattedResponseType> => {
  let response;
  const formattedResponse: FormattedResponseType = {
    status: false,
    msg: '',
    data: null,
  };

  try {
    response = await Api.get(`contractterm/${TermsContractId}/show`);

    formattedResponse.status = true;
    formattedResponse.data = response.data;
  } catch (error) {
    formattedResponse.status = false;

    if (error.response) {
      if (error.response.status < 500) {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      } else {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      }
    } else if (error.request || error.message) {
      formattedResponse.msg = 'Não foi possível conectar a plataforma';
    }
  }

  return formattedResponse;
};

import styled from 'styled-components';
import { theme } from '../../global/styles/theme';

export const Title = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 24px;
  padding-top: 15px;
  padding-bottom: 5px;
  color: ${theme.colors.text.title};
`;

export const Divider = styled.hr`
  height: 2px !important;
  border-width: 0;
  color: ${theme.colors.primary};
  background-color: ${theme.colors.primary};
  opacity: 1;
  margin: 0;
`;

export const Container = styled.div`
  width: fit-content;
`;

import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { useAppSelector } from '../store';
import PrivateRoute from '../common/PrivateRoute';
import PrivateRouteCompanies from '../common/PrivateRouteCompanies';

import SignIn from '../pages/SignIn';
import Main from '../pages/Main';
import Customers from '../pages/Customers';
import CustomersDetails from '../pages/Customers/CustomersDetails';
import Contracts from '../pages/Contracts';
import ContractsDetails from '../pages/Contracts/ContractsDetails';
import Notifications from '../pages/Notifications';
import NotificationsDetails from '../pages/Notifications/NotificationsDetails';
import ResultsSatisfactionSurveys from '../pages/Notifications/ResultsSatisfactionSurveys';
import Adverts from '../pages/Customers/Adverts';
import AdvertsDetails from '../pages/Customers/Adverts/AdvertsDetails';
import Home from '../pages/Home';
import CustomersDashboard from '../pages/Customers/CustomersDashboard';
import Partners from '../pages/Partners';
import PartnersDetails from '../pages/Partners/PartnersDetails';
import PartnersDashboard from '../pages/Partners/PartnersDashboard';

const MainRoutes: React.FC = () => {
  const signed = useAppSelector(state => state.signInReducer.signed);
  const token = useAppSelector(state => state.signInReducer?.user?.token);

  return (
    <Routes>
      <Route path="/login" element={<SignIn />} />
      <Route
        element={<PrivateRouteCompanies isLogged={signed} token={token} />}
      >
        <Route index element={<Main />} />
        <Route path="/" element={<Main />} />
      </Route>
      <Route element={<PrivateRoute isLogged={signed} token={token} />}>
        <Route path="/home" element={<Home />} />
        <Route path="/customers" element={<CustomersDashboard />} />
        <Route path="/customers/customers-list" element={<Customers />} />
        <Route
          path="/customers/customers-details"
          element={<CustomersDetails />}
        />
        <Route path="/customers/contracts" element={<Contracts />} />
        <Route
          path="/customers/contracts/details"
          element={<ContractsDetails />}
        />
        <Route path="/customers/notifications" element={<Notifications />} />
        <Route
          path="/customers/notifications-details"
          element={<NotificationsDetails />}
        />
        <Route
          path="/customers/notifications-details/ResultsSatisfactionSurveys"
          element={<ResultsSatisfactionSurveys />}
        />

        <Route path="/customers/adverts" element={<Adverts />} />
        <Route
          path="/customers/adverts/adverts-details"
          element={<AdvertsDetails />}
        />
        <Route path="/customers/partners" element={<PartnersDashboard />} />
        <Route
          path="/customers/partners/partners-list"
          element={<Partners />}
        />
        <Route
          path="/customers/partners/partners-details"
          element={<PartnersDetails />}
        />
      </Route>
    </Routes>
  );
};

export default MainRoutes;

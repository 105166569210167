/* eslint-disable default-case */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Pagination } from 'react-bootstrap';
import { format, getDate, setDate } from 'date-fns';

import TitlePage from '../../../Components/TitlePage';
import UserTotal from '../../../assets/svgs/usertotal.svg';
import User from '../../../assets/images/totaluser.svg';
import People from '../../../assets/images/totalpeople.svg';
import Notification from '../../../assets/images/totalnotifications.svg';
import EmptyList from '../../../assets/svgs/emptylistclean.svg';
import Flag from '../../../Components/Flag';
import isDate from '../../../utils/isDate';
import ptBR from '../../../utils/locale';
import { useAppSelector } from '../../../store';
import '../../../custom.scss';

import { getDashboardCustomer } from '../../../services/Customer.service';

import {
  Container,
  ColumnTwelve,
  Label,
  ColumnSix,
  CardSurvey,
  TitleLabel,
  TotalNumber,
  TotalNumberRow,
  UserTotalIcon,
  UserIcon,
  LabelButton,
  CardSurveyNotification,
  CardBackgroundEffect,
  PaginationStyled,
  TableStyled,
  THead,
  Th,
  Td,
  Tr,
  EmptyListIcon,
  TitleLabelTable,
  TitleTableTotal,
  TableDashboard,
  Qtylabel,
  Totallabel,
  Qtynumber,
} from './styles';

interface PaymentsProps {
  name: string;
  cpf: string;
  value: number;
  date_payment: string;
}

const CustomersDashboard = () => {
  const PAYMENTS_PER_PAGE = 5;
  const navigate = useNavigate();

  const [totalUsers, setTotalUsers] = useState(0);
  const [totalNewUsers, setTotalNewUsers] = useState(0);
  const [totalAccess, setTotalAccess] = useState(0);
  const [totalPendingUsers, setTotalPendingUsers] = useState(0);
  const [totalPayments, setTotalPayments] = useState(0);
  const [valueTotal, setValueTotal] = useState('0');
  const [payments, setPayments] = useState<PaymentsProps[]>([]);
  const [paymentsTmp, setPaymentsTmp] = useState<PaymentsProps[]>([]);

  const [paginationItem, setPaginationItem] = useState<number[]>([]);
  const [selectedPage, setSelectedPage] = useState<number>(0);
  const [paymentsPerPage, setPaymentsPerPage] =
    useState<number>(PAYMENTS_PER_PAGE);

  const companies = useAppSelector(
    state => state.companyReducer.companySelected,
  );

  const fetchData = useCallback(async () => {
    const { data, msg, status } = await getDashboardCustomer(
      companies.id_group,
    );

    if (!status) toast.error(msg);
    else {
      const paymentsSummary = data?.paymentsSummary?.payments || [];
      setTotalUsers(data.totalUsers);
      setTotalNewUsers(data.totalNewUsers);
      setTotalAccess(data.totalAccess);
      setTotalPendingUsers(data.totalPendingUsers);
      setTotalPayments(data?.paymentsSummary?.qty_total || 0);
      setValueTotal(data?.paymentsSummary?.value_total || '0');
      setPayments(paymentsSummary || []);
      setPaymentsTmp(paymentsSummary.slice(selectedPage, paymentsPerPage));

      const countItem = [];
      for (
        let num = 1;
        num <= Math.ceil(paymentsSummary.length / PAYMENTS_PER_PAGE);
        num += 1
      ) {
        countItem.push(num);
      }
      setPaginationItem(countItem);
    }
  }, [companies.id_group, paymentsPerPage, selectedPage]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleNavigateToCustomers = () => {
    navigate('/customers/customers-list', {
      state: { filtered: true },
    });
  };

  const changePage = (page: number) => {
    if (page > 0 && page <= paginationItem.length) {
      setSelectedPage(page);
      setPaymentsPerPage(page + 5);
      setPaymentsTmp(payments.slice(page, page + 5));
    }
  };

  const hideCpf = (cpf: string) => {
    const cpfArr = cpf.split('');

    return `${cpfArr.splice(0, 3).join('')}.${cpfArr
      .splice(0, 3)
      .join('')}.******`;
  };

  const formatDate = (date: string) => {
    if (!isDate(date)) return '';

    let newDate = new Date(
      date.indexOf('T') !== -1 ? date.split('T')[0] : date,
    );

    newDate = setDate(newDate, getDate(newDate) + 1);

    return format(newDate, "dd 'de' MMMM", {
      locale: ptBR,
    });
  };

  return (
    <Container>
      <TitlePage title="Visão Geral" />

      <ColumnTwelve
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <ColumnSix
          style={{
            flex: '0 0 39%',
          }}
        >
          <CardBackgroundEffect>
            <Label
              style={{
                display: 'flex',
                marginTop: '10px',
                alignItems: 'baseline',
              }}
            >
              <TitleLabel
                style={{
                  fontSize: '1.4rem',
                  margin: 'auto',
                  color: '#fff',
                }}
              >
                Total de clientes
              </TitleLabel>
            </Label>
            <UserTotalIcon style={{ backgroundImage: `url(${UserTotal})` }} />
            <TotalNumber style={{ marginBottom: '1.5rem' }}>
              {totalUsers}
            </TotalNumber>
          </CardBackgroundEffect>
        </ColumnSix>
        <ColumnSix style={{ flex: '0 0 59%', flexDirection: 'column' }}>
          <CardSurveyNotification>
            <ColumnTwelve>
              <CardSurvey
                style={{
                  background: '#F6F6BD',
                  cursor: 'pointer',
                }}
                onClick={handleNavigateToCustomers}
              >
                <TotalNumberRow>{totalPendingUsers}</TotalNumberRow>
                <Label
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <UserIcon
                    style={{ backgroundImage: `url(${Notification})` }}
                  />
                  <TitleLabel
                    style={{
                      fontSize: '1.4rem',
                      fontWeight: '600',
                      marginBottom: 0,
                    }}
                  >
                    Alterações pendentes
                  </TitleLabel>
                  <LabelButton style={{ marginLeft: 'auto' }}>
                    visualizar
                  </LabelButton>
                </Label>
              </CardSurvey>
            </ColumnTwelve>
          </CardSurveyNotification>

          <ColumnTwelve>
            <CardSurvey
              style={{
                background: '#D0E6EA',
              }}
            >
              <TotalNumberRow>{totalNewUsers}</TotalNumberRow>
              <Label
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <UserIcon style={{ backgroundImage: `url(${User})` }} />
                <TitleLabel
                  style={{
                    fontSize: '1.4rem',
                    fontWeight: '600',
                    marginBottom: 0,
                  }}
                >
                  Novos clientes
                  <TitleLabel
                    style={{
                      paddingTop: '5px',
                      fontSize: '14px',
                      marginBottom: 0,
                    }}
                  >
                    Número total de clientes ativos no mês
                  </TitleLabel>
                </TitleLabel>
              </Label>
            </CardSurvey>
          </ColumnTwelve>
          <ColumnTwelve>
            <CardSurvey
              style={{
                background: '#E7E3E2',
              }}
            >
              <TotalNumberRow>{totalAccess}</TotalNumberRow>
              <Label
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <UserIcon style={{ backgroundImage: `url(${People})` }} />
                <TitleLabel
                  style={{
                    fontSize: '1.4rem',
                    fontWeight: '600',
                    marginBottom: 0,
                  }}
                >
                  Total de acessos
                  <TitleLabel
                    style={{
                      paddingTop: '5px',
                      fontSize: '14px',
                      marginBottom: 0,
                    }}
                  >
                    Número total de acessos individuais ao mês
                  </TitleLabel>
                </TitleLabel>
              </Label>
            </CardSurvey>
          </ColumnTwelve>
        </ColumnSix>
      </ColumnTwelve>

      {payments.length > 0 && (
        <TableDashboard>
          <TitleTableTotal>
            <TitleLabelTable style={{ marginBottom: '0px' }}>
              Título Recebidos
            </TitleLabelTable>
            <TitleLabel
              style={{
                fontWeight: '500',
                marginBottom: 0,
                display: 'flex',
                fontSize: '14px',
              }}
            >
              <Qtylabel>
                Quantidade: <Qtynumber>{totalPayments}</Qtynumber>
              </Qtylabel>
              <Totallabel>
                Total: <Qtynumber>{`R$ ${valueTotal}`}</Qtynumber>
              </Totallabel>
            </TitleLabel>
          </TitleTableTotal>
          <TableStyled
            style={{
              margin: '0.5rem auto',
            }}
            hover
          >
            <THead>
              <tr>
                <Th>Nome</Th>
                <Th>CPF</Th>
                <Th>Valor</Th>
                <Th>Data de pagamento</Th>
              </tr>
            </THead>
            <tbody>
              {paymentsTmp.map((payment: PaymentsProps) => (
                <Tr>
                  <Td>{payment.name}</Td>
                  <Td>
                    <Flag text={hideCpf(payment.cpf)} />
                  </Td>
                  <Td>{`R$ ${payment.value}`}</Td>
                  <Td>{formatDate(payment.date_payment || '')}</Td>
                </Tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td
                  style={{
                    padding: '10px !important',
                  }}
                  colSpan={5}
                >
                  <PaginationStyled size="sm">
                    <Pagination.Prev
                      onClick={() => changePage(selectedPage - 1)}
                    />
                    {paginationItem.map((page: number) => (
                      <Pagination.Item
                        key={page}
                        active={page === selectedPage}
                        onClick={() => changePage(page)}
                      >
                        {page}
                      </Pagination.Item>
                    ))}
                    <Pagination.Next
                      onClick={() => changePage(selectedPage + 1)}
                    />
                  </PaginationStyled>
                </td>
              </tr>
            </tfoot>
          </TableStyled>
        </TableDashboard>
      )}
      {payments.length === 0 && (
        <CardSurvey>
          <EmptyListIcon style={{ backgroundImage: `url(${EmptyList})` }} />
        </CardSurvey>
      )}
    </Container>
  );
};

export default CustomersDashboard;

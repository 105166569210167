/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import UserType from '../../../types/user.type';

export interface SignInState {
  signed: boolean;
  loading: boolean;
  user: UserType | null;
  msg: string;
}

const initialState: SignInState = {
  signed: false,
  loading: false,
  msg: '',
  user: null,
};

type SignInPayload = {
  email: string;
  password: string;
};

export const signInSlice = createSlice({
  name: 'signIn',
  initialState,
  reducers: {
    signInRequest: (state, action: PayloadAction<SignInPayload>) => {
      state.loading = true;
      state.msg = '';
      state.user = null;
    },
    signInSuccess: (state, action: PayloadAction<UserType>) => {
      state.loading = false;
      state.signed = true;
      state.user = action.payload;
      state.msg = '';
    },
    signFailure: (state, action: PayloadAction<string>) => {
      state.signed = false;
      state.user = null;
      state.loading = false;
      state.msg = action.payload;
    },
    signOut: state => {
      state.signed = false;
      state.loading = false;
      state.user = null;
      state.msg = '';
    },
  },
});

// Action creators are generated for each case reducer function
export const { signInRequest, signInSuccess, signFailure, signOut } =
  signInSlice.actions;

export default signInSlice.reducer;

import React from 'react';
import ReactIcon from 'react-icons';
import { FormControlProps } from 'react-bootstrap';

import { Container, Input, ContainerIcon } from './styles';

interface InputProps extends FormControlProps {
  name: string;
  icon?: React.ComponentType<ReactIcon.IconBaseProps>;
}

const defaultProps = {
  icon: undefined,
};

const InputSearch: React.FC<InputProps> = ({ icon: Icon, ...rest }) => {
  return (
    <Container>
      <Input {...rest} />
      <ContainerIcon>{Icon && <Icon size={30} />}</ContainerIcon>
    </Container>
  );
};

InputSearch.defaultProps = defaultProps;

export default InputSearch;

import FormattedResponseType from '../types/formattedResponse.type';
// import SessionType from '../types/session.type';
import Api from './api';

const signIn = async (
  email: string,
  password: string,
): Promise<FormattedResponseType> => {
  let sessionResponse;
  const formattedResponse: FormattedResponseType = {
    status: false,
    msg: '',
    data: null,
  };

  try {
    sessionResponse = await Api.post('user/session/create', {
      email,
      password,
    });

    formattedResponse.status = true;
    formattedResponse.data = sessionResponse.data;
  } catch (error) {
    formattedResponse.status = false;

    if (error.response) {
      if (error.response.status < 500) {
        switch (error.response.data.code) {
          case 'user_not_found':
            formattedResponse.msg = 'Usuário não encontrado';
            break;

          case 'password_does_not_match':
            formattedResponse.msg = 'Senha incorreta';
            break;

          default:
            formattedResponse.msg = 'Não foi possível conectar a plataforma';
            break;
        }
      } else {
        formattedResponse.msg = 'Não foi possível conectar a plataforma';
      }
    } else if (error.request || error.message) {
      formattedResponse.msg = 'Não foi possível conectar a plataforma';
    }
  }

  return formattedResponse;
};

const authService = {
  signIn,
};

export default authService;

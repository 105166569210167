import styled, { css } from 'styled-components';
import { theme } from '../../global/styles/theme';

interface Props {
  status: string;
}

const colors = theme.colors.flag;

export const Container = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  display: inherit;
  padding: 0px 16px;

  ${(props: Props) => {
    switch (props.status) {
      case 'success':
        return css`
          color: ${colors.success.primary};
          background: ${colors.success.background};
        `;
      case 'warning':
        return css`
          color: ${colors.warning.primary};
          background: ${colors.warning.background};
        `;
      case 'error':
        return css`
          color: ${colors.error.primary};
          background: ${colors.error.background};
        `;
      default:
        return css`
          color: ${colors.grey.primary};
          background: ${colors.grey.background};
        `;
    }
  }}
`;

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin: 4.5rem auto;
  margin-top: 3.5rem;
  margin-bottom: 0;
  padding-left: 8rem;
  padding-right: 0px;
`;

export const Context = styled.div`
  width: 100%;
  margin: 2rem auto;
  margin-bottom: 0;
`;

export const WrapperIFrame = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 88vh;
`;

export const IFrame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;

  .kYdYmE {
    width: 100%;
    margin: -0.7rem 0px 0px;
    padding-left: 7rem;
    padding-right: initial;
    height: 100%;
  }
  .bSLJtP {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 90vh;
  }
`;

import React from 'react';
import { Wrapper, Container, Context } from './styles';
import Header from '../../../Components/Header';

type LayoutProps = {
  children: React.ReactNode;
};

const DefaultLayout = ({ children }: LayoutProps) => {
  return (
    <Wrapper>
      <Container>
        <Header />
        <Context>{children}</Context>
      </Container>
    </Wrapper>
  );
};

export default DefaultLayout;

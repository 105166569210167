import styled from 'styled-components';
import { Button, Spinner, ButtonGroup, Form, Accordion } from 'react-bootstrap';
import { theme } from '../../../global/styles/theme';
import Input from '../../../Components/Input';

import { ReactComponent as ArrowLeftIcon } from '../../../assets/svgs/arrow-left.svg';
import { ReactComponent as HelpIcon } from '../../../assets/svgs/help.svg';

const { colors } = theme;

export const Container = styled.div`
  width: 100%;
  margin: 4.5rem auto;
  padding-left: 8rem;
  padding-right: 1.6rem;
`;

export const Context = styled.div`
  display: flex;
  width: 100%;
  margin: 2rem auto;
  flex-direction: column;
  align-items: center;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const NavGoBackContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
`;

export const AccordionButton = styled.button`
  background: #000;
`;

export const AccordionsHeaderStyled = styled(Accordion.Header)`
  button {
    background: #fff;
  }
  .accordion-item {
    border: none;
  }
`;

export const AccordionStyled = styled(Accordion)`
  width: 100%;
  margin-bottom: 8px;
  .accordion-item {
    border: solid;
    border-width: 1px;
    border-color: #e3e3e3;
  }
  button {
    padding: 7px 10px;
    font-size: 14px;
  }
  .accordion-body {
    padding: 0rem 0.25rem;
    background: #fbfbfb;
  }
`;

export const NavGoBack = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
  margin-bottom: 0;
  color: ${colors.text.tHead};
`;

export const LabelId = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  margin-bottom: 0;
  color: ${colors.text.tHead};
`;

export const NavGoBackName = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 0;
  color: ${colors.text.title};
`;

export const GoBackIcon = styled(ArrowLeftIcon)`
  margin-right: 1rem;
`;

export const HelpIconStyled = styled(HelpIcon)`
  margin-left: 0.5rem;
  width: auto;
  height: 0.8rem;
`;

export const Dot = styled.div`
  border-radius: 50%;
  background-color: ${colors.text.tHead};
  height: 6px;
  width: 6px;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
`;

export const RowForm = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 44rem;
  width: 100%;
  margin-bottom: 1.5rem;
`;

export const InputStyled = styled(Input)`
  max-width: 44rem;
  width: 100%;
  margin-top: 0.6rem;
  background-color: #ffffff00;
`;

export const Select = styled(Form.Select)`
  align-items: center;
  display: inline-block;
  vertical-align: middle;
  min-width: 10rem;
  min-height: 2.4rem;
  background-color: ${colors.white};
  -ms-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  color: ${colors.text.title};
  font-weight: 400;
  font-size: 13px;
  border: 1px solid ${colors.borderInput};
  box-sizing: border-box;
  border-radius: 8px;
  max-width: 44rem;
  width: 100%;
  margin-top: 0.6rem;
  background-color: #ffffff00;
`;

export const ButtonGroupStyled = styled(ButtonGroup)`
  margin-top: 0.6rem;
`;

export const Label = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  align-items: center;
`;

export const LabelTooltip = styled.span`
  display: flex;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  display: block;

  @media (max-width: 550px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
`;
export const Editor = styled.div`
  rdw-image-wrapper {
    display: none;
  }
  rdw-embedded-wrapper {
    display: none;
  }
  span {
    color: #9d6fc7;
    text-decoration: underline;
    cursor: pointer;
  }
`;
export const ButtonStyledPaper = styled.div`
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  border: 1px solid #d7d7d7;
  padding: 1rem 0.6rem;
  font-size: 11px;
  border-radius: 100rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #878787;
  background-color: #ffffff;
  line-height: 0;
  max-height: 21px;
  margin: 0px 2.5px;
  :hover {
    color: #8d5abc;
    border-color: #8d5abc;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  :focus {
    color: #ffff;
    border-color: #8d5abc;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    background-color: #8d5abc;
  }
  :active {
    color: #8d5abc;
    border-color: #8d5abc;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
`;

export const ButtonStyled = styled(Button)`
  margin-left: 0.6rem;
  border-radius: 10px;

  @media (max-width: 550px) {
    margin-left: 0;
    margin-top: 1rem;
    width: 100%;
  }
`;

export const LoginSpinner = styled(Spinner)`
  height: 1.4rem;
  width: 1.4rem;
`;

import translations from '../common/translation/index.json';

const getTranslation = (text: string) => {
  if (!text) return '';
  // @ts-ignore
  const translatedText = translations[text];

  if (!translatedText) return '';

  return translatedText;
};

export default getTranslation;

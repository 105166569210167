import React from 'react';

import { DividerFormStyled, Divider } from './styles';

const DividerForm = () => {
  return (
    <DividerFormStyled>
      <Divider />
    </DividerFormStyled>
  );
};

export default DividerForm;

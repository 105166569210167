import styled from 'styled-components';
import { Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import InputOutlined from '../../Components/InputOutlined';
import { theme } from '../../global/styles/theme';

export const InputOutlinedStyled = styled(InputOutlined)`
  margin-left: 0.4rem;
  background: ${theme.colors.white};
  padding-bottom: 0;
`;

export const InputContent = styled.div`
  margin-bottom: 3.2rem;
`;

export const Container = styled.div`
  overflow: hidden;
`;

export const Layout = styled(Row)`
  display: flex;
`;

export const Login = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
`;

export const LoginWrapContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  height: 100vh;
  max-width: 440px;
  min-width: 311px;
  min-height: 100%;
  padding: 30px 30px 0px 30px;
`;

export const LoginBody = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LogoView = styled.img`
  width: 50px;
  margin-bottom: 10px;
`;

export const EnterTitle = styled.h1`
  margin-bottom: 10px;
`;

export const EnterDescription = styled.h6`
  margin-bottom: 50px;
`;

export const LoginForm = styled(Form)`
  display: flex;
  flex-direction: column;
`;

export const ButtonsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14%;
`;

export const ShowPasswordContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ShowPasswordTitle = styled.h6`
  display: flex;
  padding-right: 15px;
  padding-top: 8px;
  font-size: 13px;
`;

export const ShowPasswordCheck = styled(Form.Switch)`
  display: flex;
`;

export const EnterButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 40px;
`;

export const ErrorMessage = styled.p`
  align-self: center;
  color: red;
`;

export const LoginSpinner = styled(Spinner)``;

export const LoginFooter = styled.p`
  display: flex;
  font-size: 13px;
  font-weight: 400;
`;

export const Banner = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: auto;
  height: 100vh;
  background-color: #8d5abc;

  @media (max-width: 991px) {
    display: none;
  }
`;

export const BannerImg = styled.img`
  max-width: 95%;
  max-height: 95%;
  width: auto;
  height: auto;
`;
